



























































































































































































































































































































































import Base from '@/mixins/Base.vue';
import { IClientsResponse, IClient } from '@/interfaces/client';
import { IUser, IUserResponse } from '@/interfaces/user';
import { IClientUserType } from '@/interfaces/clientusertype';
import { IRole } from '@/interfaces/role';
import { IPagination } from '@/interfaces/pagination';
import { IPermission } from '@/interfaces/permission';

const component = Base.extend({
    props: {
        user: {
            type: Object as () => IUser,
            default: null,
        },

    },
    mounted() {
        this.getUserClients();
    },
    data() {
        return {
            isAddUserClientModalActive: false,
            clients: {
                data: [] as Array<IClient>,
                meta: {} as IPagination | any,
            } as IClientsResponse,
            params: {
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,
            },
            newData: {
                client_user_type: undefined as any | IClientUserType,
                role: undefined as any | IRole,
                extra_permissions: [] as Array<IPermission>,
                client: undefined as any | IClient,
            } as any,
        };
    },
    methods: {
        getUserClients(page: number = 1): void {
            this.params.page = page;
            this.get<IClientsResponse>(`users/${this.$route.params.id}/clients`, {
                params: this.params,
            }).then(({ data }) => {
                this.clients = data;
            });
        },
        addUserClient(): void {
            this.post(`users/${this.$route.params.id}/clients`, {
                role: this.newData.role ? this.newData.role.name : null,
                extra_permissions: this.newData.extra_permissions.map((p: IPermission) => p.id),
                client_id: this.newData.client ? this.newData.client.id : null,
                client_user_type_id: this.newData.client_user_type ? this.newData.client_user_type.id : null,
            }).then(() => {
                this.getUserClients();
                this.isAddUserClientModalActive = false;
            });
        },
        saveUserClient(client: IClient): void {
            if (client.client_user && client.client_user.role && client.client_user.client_user_type) {
                this.put<IUserResponse>(`users/${this.$route.params.id}/clients/${client.id}`, {
                    client_id: client.id,
                    role: client.client_user.role ? client.client_user.role.name : null,
                    extra_permissions: client.client_user.extra_permissions.map((p: IPermission) => p.id),
                    client_user_type_id: client.client_user.client_user_type ? client.client_user.client_user_type.id : null,
                }).then(() => {
                    this.getUserClients();
                });
            }
        },
        deleteUserClient(client: IClient): void {
            this.confirm().then(() => {
                this.delete(`users/${this.$route.params.id}/clients/${client.id}`)
                    .then(() => {
                        this.getUserClients();
                    });
            });
        },
    },
});

export default component;
