var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'users.list'},"icon-left":"arrow-left"}}),( ! _vm.user)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm.user.name)+" ")])]],2)])]),(_vm.user)?_c('b-tabs',{staticClass:"block",attrs:{"position":"is-centered","expanded":"","destroy-on-hide":""},on:{"input":function($event){_vm.activeTab = $event}}},[_c('b-tab-item',{attrs:{"label":"General","value":"general","icon":"info-circle"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveUser)}}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"firstname","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Firstname","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"lastname","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Lastname","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"country_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Country","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-country',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.country),callback:function ($$v) {_vm.$set(_vm.user, "country", $$v)},expression:"user.country"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Account ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"username","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Username","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"new_password","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Password","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"password","disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"generic_role","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Generic role","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-role',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.generic_role),callback:function ($$v) {_vm.$set(_vm.user, "generic_role", $$v)},expression:"user.generic_role"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"lang_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Language","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-language',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.lang),callback:function ($$v) {_vm.$set(_vm.user, "lang", $$v)},expression:"user.lang"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"active","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Status","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-switch',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}},[_vm._v(" Active ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Contact ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"email","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"E-mail","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"phone","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Phone","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Settings ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"no_end_client","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Mention end customer in job","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.no_end_client),callback:function ($$v) {_vm.$set(_vm.user, "no_end_client", $$v)},expression:"user.no_end_client"}},[_c('option',{domProps:{"value":1}},[_vm._v(" No ")]),_c('option',{domProps:{"value":0}},[_vm._v(" Yes ")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"range","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Request visibility","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.range),callback:function ($$v) {_vm.$set(_vm.user, "range", $$v)},expression:"user.range"}},[_c('option',{domProps:{"value":0}},[_vm._v(" Only own applications ")]),_c('option',{domProps:{"value":1}},[_vm._v(" Applications within the client/office ")]),_c('option',{domProps:{"value":2}},[_vm._v(" All requests, including those from all indirect relationships (chain) ")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"auto_inspection_data","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Taking over inspection data from client information","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.auto_inspection_data),callback:function ($$v) {_vm.$set(_vm.user, "auto_inspection_data", $$v)},expression:"user.auto_inspection_data"}},[_c('option',{domProps:{"value":0}},[_vm._v(" No ")]),_c('option',{domProps:{"value":1}},[_vm._v(" Yes, ask for it ")]),_c('option',{domProps:{"value":2}},[_vm._v(" Yes (fill in automatically) ")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" E-mail options ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"mailopts","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Options","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-field',[_c('b-checkbox',{attrs:{"native-value":'1',"disabled":_vm.isLoading},model:{value:(_vm.user.mailopts),callback:function ($$v) {_vm.$set(_vm.user, "mailopts", $$v)},expression:"user.mailopts"}},[_vm._v(" Acknowledgment of receipt (omnicasa / whise / Fortissimmo) ")])],1),_c('b-field',[_c('b-checkbox',{attrs:{"native-value":'2',"disabled":_vm.isLoading},model:{value:(_vm.user.mailopts),callback:function ($$v) {_vm.$set(_vm.user, "mailopts", $$v)},expression:"user.mailopts"}},[_vm._v(" Inspection date ")])],1),_c('b-field',[_c('b-checkbox',{attrs:{"native-value":'3',"disabled":_vm.isLoading},model:{value:(_vm.user.mailopts),callback:function ($$v) {_vm.$set(_vm.user, "mailopts", $$v)},expression:"user.mailopts"}},[_vm._v(" Part of your order is ready ")])],1),_c('b-field',[_c('b-checkbox',{attrs:{"native-value":'4',"disabled":_vm.isLoading},model:{value:(_vm.user.mailopts),callback:function ($$v) {_vm.$set(_vm.user, "mailopts", $$v)},expression:"user.mailopts"}},[_vm._v(" All certificates are available ")])],1),_c('b-field',[_c('b-checkbox',{attrs:{"native-value":'5',"disabled":_vm.isLoading},model:{value:(_vm.user.mailopts),callback:function ($$v) {_vm.$set(_vm.user, "mailopts", $$v)},expression:"user.mailopts"}},[_vm._v(" Job cancellation (installer) ")])],1)],1)])]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Links ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"whise_office_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Whise office ID","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.whise_office_id),callback:function ($$v) {_vm.$set(_vm.user, "whise_office_id", $$v)},expression:"user.whise_office_id"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"fortissimo_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Fortissimo ID","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.fortissimmo_id),callback:function ($$v) {_vm.$set(_vm.user, "fortissimmo_id", $$v)},expression:"user.fortissimmo_id"}})],1)]}}],null,true)})],1),_c('b-field',{attrs:{"horizontal":""}},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"save","disabled":! valid || _vm.isLoading},on:{"click":function($event){return _vm.saveUser()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}],null,false,3570389066)})],1),_c('b-tab-item',{directives:[{name:"permission",rawName:"v-permission",value:('users.clients.read'),expression:"'users.clients.read'"}],attrs:{"label":"Clients","value":"clients","icon":"building"}},[_c('clients',{attrs:{"user":_vm.user,"active":_vm.activeTab === 'clients',"error":_vm.errors,"loading":_vm.isLoading},on:{"error":function($event){return _vm.$emit('error',$event)},"success":function($event){return _vm.$emit('success',$event)}}})],1),_c('b-tab-item',{directives:[{name:"permission",rawName:"v-permission",value:('users.logs.read'),expression:"'users.logs.read'"}],attrs:{"label":"Logs","value":"logs","icon":"building"}},[_c('logs',{attrs:{"user":_vm.user,"active":_vm.activeTab === 'logs',"error":_vm.errors,"loading":_vm.isLoading},on:{"error":function($event){return _vm.$emit('error',$event)},"success":function($event){return _vm.$emit('success',$event)}}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }