































































































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';
import { IUser, IUserResponse } from '@/interfaces/user';
import clients from './partials/Clients.vue';
import logs from './partials/Logs.vue';

const component = Base.extend({
    components: {
        clients,
        logs,
    },
    data() {
        return {
            activeTab: 0,
            user: undefined as any | IUser,
            new_password: null,
        };
    },

    mounted() {
        this.getUser();
    },
    methods: {
        getUser() : void{
            this.get<IUserResponse>(`users/${this.$route.params.id}`).then(({ data }) => {
                this.user = data.data;
            });
        },
        saveUser() : void{
            this.put<IUserResponse>(`users/${this.$route.params.id}`, {
                ...this.user,
                new_password: this.new_password,
                generic_role: this.user.generic_role ? this.user.generic_role.name : null,
                lang_id: this.user.lang ? this.user.lang.id : null,
                country_id: this.user.country ? this.user.country.id : null,
            }).then(({ data }) => {
                this.user = data.data;
                this.$emit('success', { message: `user ${data.data.name} updated` });

                if (this.currentUser.id === this.user.id && this.currentUser.generic_role && this.currentUser.generic_role.id !== this.user.generic_role.id) {
                    this.$emit('reset-client');
                }
            });
        },

    },
});

export default component;
